import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { MaintenanceMode, Exception } from 'componentlibrary';
import { createBrowserHistory } from 'history';
import { routesWithPermission } from '../../routes';

const defaultHistory = createBrowserHistory();

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: null
    };
  }

  componentDidMount() {
    this.setAuth();
    this.getAppConfig();
  }

  componentDidUpdate() {
    const { context } = this.props;
    const { refreshKey } = this.state;

    if (context.refreshKey !== refreshKey) {
      this.refresh(context.refreshKey);
    }
  }

  get permissionDenied() {
    const { t } = this.props;

    return (
      <Exception
        status={403}
        message={t('errors:403')}
      />
    );
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  setAuth() {
    const { context, setAuth } = this.props;
    setAuth(context.getIdToken, context.permission);
  }

  refresh(refreshKey) {
    const { history } = this.props;
    history.push('/targets');

    this.setState({
      refreshKey
    });
  }

  render() {
    const {
      t, appConfig, context, history
    } = this.props;

    if (!appConfig) {
      return null;
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:serviceDown')}
          description={t('maintenanceMode:pleaseTryAgain')}
        />
      );
    }

    return (
      <Router history={history || defaultHistory}>
        <div style={{ margin: '20px' }}>
          {renderRoutes(
            routesWithPermission(
              context.permission,
              this.permissionDenied
            )
          )}
        </div>
      </Router>
    );
  }
}

App.defaultProps = {
  appConfig: null,
};

App.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(App);
