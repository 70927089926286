import Config from '../Config';

const APP_CONFIG_SET_ACTION = 'APP_CONFIG_SET';

export function appConfigDefaultState() {
  return null;
}

export async function getAppConfig(dispatch) {
  const result = await fetch(`${Config.apiGateway.INTERNAL_API_URL}/app-config`);
  const payload = await result.json();

  dispatch({
    type: APP_CONFIG_SET_ACTION,
    payload
  });
}

export default (state = appConfigDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case APP_CONFIG_SET_ACTION:
      return {
        maintenanceMode: payload.maintenance_mode,
        appVersion: payload.app_version
      };

    default:
      return state;
  }
};
