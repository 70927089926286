import React, { Component } from "react";
import { Col, Row, Select, Card, Icon } from "antd";

export class TargetsFilter extends Component {
  generateSelect(select_config, with_offset) {
    const field_name = select_config.field_name;
    const {
      filterOptions,
      setFilter,
      activeFilters,
      getAllTargets,
      isLoading,
    } = this.props;

    return (
      <Col md={4} xs={24} offset={+with_offset} className="search">
        <Icon
          type={select_config.icon}
          style={{ color: "#1168e3", marginRight: 3 }}
        />
        <span className={"team-label"}>{select_config.label}:</span>
        <Select
          label={select_config.label}
          loading={isLoading}
          defaultValue={activeFilters[field_name][0]}
          disabled={isLoading}
          placeholder={"Select a " + select_config.label}
          dropdownMatchSelectWidth={false}
          style={{ width: "100%" }}
          showSearch={true}
          filterOption={(input, option) => {
            if (
              "value" in option.props === false ||
              "children" in option.props === false
            )
              return false;

            return (
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(selectedOption) => {
            setFilter(field_name, selectedOption, activeFilters);
            getAllTargets();
          }}
        >
          <Select.Option key={"all"}>Select all</Select.Option>
          {Object.keys(filterOptions[field_name])
            .sort((a, b) =>
              filterOptions[field_name][a].localeCompare(
                filterOptions[field_name][b]
              )
            )
            .map((team) => (
              <Select.Option key={team} value={team}>
                {filterOptions[field_name][team]}
              </Select.Option>
            ))}
        </Select>
      </Col>
    );
  }

  render() {
    let filter_select_config = [
      {
        label: "Team",
        icon: "team",
        field_name: "team_uuid",
      },
      {
        label: "Team member name",
        icon: "user",
        field_name: "team_member_uuid",
      },
      {
        label: "Team leader name",
        icon: "user",
        field_name: "team_leader_uuid",
      },
      {
        label: "Task",
        icon: "solution",
        field_name: "task_uuid",
      },
      {
        label: "Critical Risk",
        icon: "warning",
        field_name: "critical_risk_uuid",
      },
    ];

    return (
      <Card style={{ marginTop: 10, marginBottom: 15 }}>
        <h3 className={"filter-by-title"}>Filter by</h3>
        <Row>
          {filter_select_config.map((select_config, index) =>
            this.generateSelect(select_config, index !== 0)
          )}
        </Row>
      </Card>
    );
  }
}

export default (TargetsFilter);
