import { connect } from 'react-redux';

import TargetsTable from '../../Components/Targets/TargetsTable';
import { getAllTargets, setOverwriteSelectedMonth} from '../../reducers/targets';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  targets: state.targets.data,
  pagination: state.targets.pagination,
  isLoading: state.targets.loading,
  overwrite_mode: state.targets.overwrite_mode,
  overwrite_selected_month: state.targets.overwrite_selected_month,
  monthly_targets_date_from: state.targets.monthly_targets_date_from,
  risk_profile_access: state.auth.permission.teams.access,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  setOverwriteSelectedMonth(...args) {
    return setOverwriteSelectedMonth(dispatch, ...args);
  },
  getAllTargets(...args) {
    return getAllTargets(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(TargetsTable));
