import { connect } from 'react-redux';

import MonthlyTargetsOverwriter from '../../Components/Targets/MonthlyTargetsOverwriter';
import {overwriteTarget} from '../../reducers/targets';
import WithDefaults from '../WithDefaults';
import {setAuth} from "../../reducers/auth";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  update_loading: state.targets.overwrite_loading,
  overwrite_mode: state.targets.overwrite_mode,
  overwrite_selected_month: state.targets.overwrite_selected_month,
  monthly_targets_date_from: state.targets.monthly_targets_date_from,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  overwriteTarget(token, target_uuids, target_value) {
    return overwriteTarget(dispatch, token, target_uuids, target_value);
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(MonthlyTargetsOverwriter));
