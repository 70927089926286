import { connect } from 'react-redux';

import TargetsUpdater from '../../Components/Targets/TargetsUpdater';
import {updateTarget} from '../../reducers/targets';
import WithDefaults from '../WithDefaults';
import {setAuth} from "../../reducers/auth";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  update_loading: state.targets.update_loading,
  active_filters: state.targets.active_filters,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  updateTarget(token, active_filters, target_uuids, target_value) {
    return updateTarget(dispatch, token, active_filters, target_uuids, target_value);
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(TargetsUpdater));
