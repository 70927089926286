import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { renderRoutes } from 'react-router-config';

import Targets from './Containers/Targets';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/

const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

Root.propTypes = {
  route: PropTypes.object.isRequired
};

const makeRoutes = () => [
  {
    path: '/targets',
    exact: true,
    component: Targets,
    permission: ['teamTargets.access']
  }
];

const routePermitted = (routePermission, permission) => routePermission.every((r) => {
  if (!lodash.get(permission, r)) {
    return false;
  }
  return true;
});

export const routesWithPermission = (permission, fallbackComponent) => {
  const PermissionDenied = () => {
    return fallbackComponent;
  }

  const routes = makeRoutes();

  routes.forEach((route) => {
    if (!routePermitted(route.permission, permission)) {
      route.component = PermissionDenied;
    }
  });

  return [
    {
      component: Root,
      routes: routes,
    }
  ];
};
