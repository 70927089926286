const AUTH_SET_ACTION = 'AUTH_SET';

export function authDefaultState() {
  return {
    getIdToken: null,
    permission: null,
  };
}

export function setAuth(dispatch, getIdToken, permission) {
  return dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      getIdToken,
      permission
    }
  });
}

export default (state = authDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case AUTH_SET_ACTION:
      return {
        getIdToken: payload.getIdToken,
        permission: payload.permission,
      };

    default:
      return state;
  }
};
