import moment from 'moment-timezone';
import Config from '../Config';

const TARGETS_GET_LIST_ACTION = 'TARGETS_GET_LIST';
const TARGETS_CLEAR_DATA_ACTION = 'TARGETS_CLEAR_DATA';
const TARGETS_INIT_FILTER_ACTION = 'TARGETS_INIT_FILTER_ACTION';
const TARGETS_SET_FILTER = 'TARGETS_SET_FILTER';
const TOGGLE_LOADING = 'TOGGLE_LOADING';
const TARGETS_UPADTE_PROCESSING = 'TARGETS_UPADTE_PROCESSING';
const TARGETS_TOGGLE_OVERWRITING = 'TARGETS_TOGGLE_OVERWRITING';
const TARGETS_SET_OVERWRITE_MONTH = 'TARGETS_SET_OVERWRITE_MONTH';
const OVERWRITE_TARGETS_UPADTE_PROCESSING = 'OVERWRITE_TARGETS_UPADTE_PROCESSING';
const MONTHLY_TARGETS_SET_FILTER_OPTIONS = 'MONTHLY_TARGETS_SET_FILTER_OPTIONS';

const { search } = window.location;
const params = new URLSearchParams(search);
const teamUuidQueryParam = params.get('team_uuid');

const filterDefault = {
  team_uuid: teamUuidQueryParam ? [teamUuidQueryParam] : [],
  team_member_uuid: [],
  team_leader_uuid: [],
  task_uuid: [],
  critical_risk_uuid: [],
};

export function targetsDefaultState() {
  return {
    data: [],
    filter_options: filterDefault,
    active_filters: filterDefault,
    loading: false,
    update_loading: [],
    overwrite_loading: [],
    overwrite_mode: false,
    overwrite_selected_month:
      moment().format('D') > 6
        ? moment().format('YYYY-MM')
        : moment().subtract(1, 'months').format('YYYY-MM'),
    monthly_targets_date_from: new Date(),
    pagination: {
      current: 1, pageSize: 20, total: 0
    }
  };
}

export function toggleLoading(dispatch) {
  dispatch({
    type: TOGGLE_LOADING,
  });
}

const serialize = (filter = {}, join = '&') => {
  const query = [];

  Object.keys(filter).forEach((key) => {
    if (filter[key].length === 0 || filter[key][0] === 'all') return;
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`);
  });

  if (query.length === 0) {
    return '';
  }

  return `${query.join(join)}`;
};

async function api(
  dispatch,
  token,
  apiParams,
  endpoint = 'target/filter',
  method = 'GET',
  body = null
) {
  const {
    query = null,
    endpointConst = endpoint,
    method_const = method,
    requestBody = body,
    contentType = 'application/json',
  } = apiParams;

  const fetchConfig = {
    method: method_const,
    headers: {
      'content-type': contentType,
      authorization: token,
    },
  };

  if (requestBody) {
    if (contentType === 'application/json') {
      fetchConfig.body = JSON.stringify(requestBody);
    } else {
      fetchConfig.body = requestBody;
    }
  }

  let fetchUrl = `${Config.apiGateway.TARGET_API_URL}/${endpointConst}`;
  if (query) {
    fetchUrl += query;
  }

  const result = await fetch(fetchUrl, fetchConfig);

  const payload = await result.json();

  if (!result.ok) {
    throw Object.assign(new Error(), {
      status: result.status,
      message: payload.errors,
    });
  }

  return payload;
}

const processMonthlyTargets = (targetsData, monthlyTargetsData) => {
  const payload = {
    ...targetsData,
    data: targetsData.data.map((item) => {
      const children = item.children?.map((target) => {
        const filterMonthlyTargets = [];
        const monthlyTargetUuids = {};
        for (const monthlyTarget of monthlyTargetsData) {
          if (monthlyTarget.target_uuid === target.target_uuid) {
            const year = monthlyTarget.attributes.target_year;
            const month = monthlyTarget.attributes.target_month.toString().padStart(2, '0');
            const dateObj = `${year}-${month}`;
            filterMonthlyTargets.push(monthlyTarget);
            if (monthlyTargetUuids[dateObj]) {
              monthlyTargetUuids[dateObj].push(monthlyTarget.id);
            } else {
              monthlyTargetUuids[dateObj] = [monthlyTarget.id];
            }
          }
        }
        return {
          ...target,
          monthly_targets: filterMonthlyTargets,
          monthly_target_uuids: monthlyTargetUuids,
        };
      });
      return { ...item, children };
    }),
  };
  return payload;
}

export async function getAllTargets(
  dispatch,
  token,
  filter = {},
  showLoading = true
) {
  if (showLoading) {
    toggleLoading(dispatch);
  }

  filter.is_active = 1;
  filter.is_structured = true;

  const apiParams = {
    query: `?${serialize(filter, '&')}`,
  };

  const [targetsApiResponse, monthlyTargetsApiResponse] = await Promise.all([
    api(dispatch, token, apiParams),
    api(dispatch, token, apiParams, 'monthly-target'),
  ]);

  const payload = processMonthlyTargets(targetsApiResponse, monthlyTargetsApiResponse.data);

  dispatch({
    type: TARGETS_GET_LIST_ACTION,
    payload: {
      data: payload.data,
      meta: payload.meta
    },
  });

  dispatch({
    type: MONTHLY_TARGETS_SET_FILTER_OPTIONS,
    payload: {
      monthly_date_from: payload.monthly_date_from,
    },
  });

  if (showLoading) {
    toggleLoading(dispatch);
  }
}

// eslint-disable-next-line no-unused-vars, no-empty-function
export async function initTargetFilters(dispatch, token) {
  const filterOptionsResponse = await api(dispatch, token, {}, 'target/filter-options');
  dispatch({
    type: TARGETS_INIT_FILTER_ACTION,
    payload: filterOptionsResponse,
  });
}

export function clearTargetsData(dispatch) {
  dispatch({
    type: TARGETS_CLEAR_DATA_ACTION,
  });
}

export function toggleOverwriteMode(dispatch) {
  dispatch({
    type: TARGETS_TOGGLE_OVERWRITING,
  });
}

export function setOverwriteSelectedMonth(dispatch, selectedMonth) {
  dispatch({
    type: TARGETS_SET_OVERWRITE_MONTH,
    payload: selectedMonth,
  });
}

export async function setFilter(
  dispatch,
  filterKey,
  filterValue,
  currentActiveFilters
) {
  const copy = currentActiveFilters;
  copy[filterKey] = [filterValue];
  dispatch({
    type: TARGETS_SET_FILTER,
    payload: copy,
  });
}

export async function updateTarget(
  dispatch,
  token,
  active_filters,
  targetUuids,
  targetValue
) {
  dispatch({
    type: TARGETS_UPADTE_PROCESSING,
    payload: {
      targets_loading: targetUuids,
    },
  });

  const promiseResult = await Promise.all(
    targetUuids.map((singleTargetUuid) => api(
      dispatch,
      token,
      params,
      `target/${singleTargetUuid}`,
      'PATCH',
      { target_month_default_value: targetValue }
    ))
  );

  await getAllTargets(dispatch, token, active_filters, false);

  dispatch({
    type: TARGETS_UPADTE_PROCESSING,
    payload: {
      targets_finished_loading: targetUuids,
    },
  });

  return promiseResult;
}

export async function overwriteTarget(
  dispatch,
  token,
  targetUuids,
  targetValue
) {
  dispatch({
    type: OVERWRITE_TARGETS_UPADTE_PROCESSING,
    payload: {
      targets_loading: targetUuids,
    },
  });

  await Promise.all([
    api(dispatch, token, params, 'monthly-target', 'PATCH', {
      monthly_target_uuids: targetUuids,
      targetValue,
    }),
  ]);

  await getAllTargets(dispatch, token, {}, false);

  dispatch({
    type: OVERWRITE_TARGETS_UPADTE_PROCESSING,
    payload: {
      targets_finished_loading: targetUuids,
    },
  });

  return true;
}

export default (state = targetsDefaultState(), action) => {
  const { payload, type } = action;
  let newState = [];
  let newMonthlyState = [];
  switch (type) {
    case TARGETS_GET_LIST_ACTION:
      return {
        ...state,
        data: payload.data,
        pagination: {
          current: payload.meta.pagination.page,
          pageSize: payload.meta.pagination.size,
          total: payload.meta.pagination.resultCount,
        }
      };

    case TARGETS_INIT_FILTER_ACTION:
      return {
        ...state,
        filter_options: payload,
      };

    case TARGETS_TOGGLE_OVERWRITING:
      return {
        ...state,
        overwrite_mode: !state.overwrite_mode,
      };

    case TARGETS_SET_OVERWRITE_MONTH:
      return {
        ...state,
        overwrite_selected_month: String(payload),
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };

    case TARGETS_CLEAR_DATA_ACTION:
      return {
        ...targetsDefaultState(),
      };

    case TARGETS_SET_FILTER:
      return {
        ...state,
        active_filters: payload,
      };

    case MONTHLY_TARGETS_SET_FILTER_OPTIONS:
      return {
        ...state,
        monthly_targets_date_from: payload.monthly_date_from,
      };

    case TARGETS_UPADTE_PROCESSING:
      if ('targets_finished_loading' in payload) {
        newState = state.update_loading.filter(
          (item) => !payload.targets_finished_loading.includes(item)
        );
      }

      if ('targets_loading' in payload) {
        newState = state.update_loading.concat(payload.targets_loading);
      }

      return {
        ...state,
        update_loading: newState,
      };

    case OVERWRITE_TARGETS_UPADTE_PROCESSING:
      if ('targets_finished_loading' in payload) {
        newMonthlyState = state.overwrite_loading.filter(
          (item) => !payload.targets_finished_loading.includes(item)
        );
      }

      if ('targets_loading' in payload) {
        newMonthlyState = state.overwrite_loading.concat(
          payload.targets_loading
        );
      }

      return {
        ...state,
        overwrite_loading: newMonthlyState,
      };

    default:
      return state;
  }
};
