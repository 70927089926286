import React from "react";
import { Row, Col, Button, Divider } from "antd";
import TargetsFilter from "./TargetsFilter";
import TargetsTable from "../../Containers/TargetsTable";
import Config from "../../Config";

class Targets extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      targets: props.targets,
    };
  }

  onFilter(filter) {
    this.setState({
      filter,
      hasList: false,
      hasStructure: false,
    });
  }

  componentDidMount() {
    this.initTargetFilters();
    this.getAllTargets();
  }

  async getAllTargets() {
    const { getAllTargets, getIdToken, activeFilters } = this.props;
    const token = await getIdToken();
    await getAllTargets(token, activeFilters);
  }

  async initTargetFilters() {
    const { initTargetFilter } = this.props;
    const { getIdToken } = this.props;
    const token = await getIdToken();
    await initTargetFilter(token);
  }

  render() {
    const { overwrite_mode, toggleOverwriteMode } = this.props;

    return [
      <Row type="flex" justify="center" align="top">
        <Col span={4}>
          <h1>
            <strong>Targets</strong>
          </h1>
          <Divider />
        </Col>
        <Col span={20} style={{ textAlign: "right" }}>
          {overwrite_mode ? (
            <Button
              onClick={() => toggleOverwriteMode()}
              ghost
              className={"overwrite-target-button"}
              style={{ backgroundColor: "#fff" }}
              type={"danger"}
              icon={"double-left"}
            >
              Cancel Editing
            </Button>
          ) : (
            [
              <a
                href={Config.apiGateway.QS_DASHBOARD_URL}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Button icon={"area-chart"}>
                  View Targets Reporting Dashboard
                </Button>
              </a>,
              <Button
                onClick={() => toggleOverwriteMode()}
                ghost
                className={"overwrite-target-button"}
                style={{ backgroundColor: "#fff" }}
                type={"danger"}
                icon={"lock"}
              >
                Edit Locked Targets
              </Button>,
            ]
          )}
          <Divider />
        </Col>
      </Row>,
      <TargetsFilter
        filterOptions={this.props.targetFilters}
        setFilter={this.props.setFilter}
        isLoading={this.props.isLoading}
        activeFilters={this.props.activeFilters}
        getAllTargets={() => this.getAllTargets()}
      />,
      <TargetsTable />,
    ];
  }
}

export default Targets;
